.clip-45 {
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);

  @media screen and (max-width: 1240px) {
    clip-path: none;
  }
}

.clip-65 {
  clip-path: polygon(0 0, 100% 0, 61% 100%, 0% 100%);

  @media screen and (max-width: 1240px) {
    clip-path: none;
  }
}

.parent-header {
  position: relative;
}

.parent-header::after {
  @media screen and (min-width: 1240px) {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    z-index: 1;
  }
}

#content {
  position: relative;
  z-index: 2;
  background-image: linear-gradient(180deg, #0B1241 0%, #4277C7 95%);

  @media screen and (min-width: 1240px) {
    background-image: none;
  }
}

#back-button {
  background-color: transparent;

  @media screen and (min-width: 1240px) {
    background-color: white;
  }
}

#accept {
  accent-color: #031356;
}
