@use "sass:map";
@import '../App.scss';

footer{
  a:hover {
    color: map.get($colors, 'violet');
  }

  a{
    text-decoration: none;
    color:#ffffff;
  }

  h6 {
    margin-bottom: 18px;
  }

  li {
    margin-bottom: 8px;
    letter-spacing: 0.1px;
  }

  .border-top {
    border-top: 1px solid #dee2e614 !important;
  }
}

.logo-footer-oc {
  height: 30px;
  @media (max-width: 1400px) {
    height: 24px;
  }
  @media (max-width: 1200px) {
    height: 20px;
  }
}

.logo-footer {
  height: 38px;
  @media (max-width: 1400px) {
    height: 32px;
  }
  @media (max-width: 1200px) {
    height: 28px;
  }
}