@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-number-input:focus + span {
  border-color: theme('colors.secondarySkyBlue');
}

.custom-number-input:focus + span.border-redError {
  border-color: theme('colors.redError');
}

.parent-outline:focus-within {
  outline: 1px solid theme('colors.secondarySkyBlue');
}

.parent-outline-red-error:focus-within {
  outline: 1px solid theme('colors.redError');
}