@use "sass:map";
@import '../../App.scss';

.login-form {
  padding-left: 24px;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .login-form {
    border-left: 0px!important;
    padding-left: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .login-left-text {
    font-weight: 600;
    font-size: 19px;
    padding-right: 5%;
  }
}

.login-left-text {
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: 0.1px;
  color: map.get($colors, 'dark');
}

.login-title {
  font-weight: 600;
  font-size: 16px;
}

.btn-connexion {
  margin-top: 10px;
  height: 44px;
  border-radius: 8px;
  background: map.get($colors, 'new-branding-primary') !important;
}