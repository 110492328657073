@use "sass:map";
@import "../../../App.scss";

.cta-header-profile-mobile {
  display: none;
  box-shadow: 0px -2px 4px 0px rgba(225, 225, 225, 0.25);
  @media (max-width: 768px) {
    display: flex;
  }
}

.cta-openclimat {
  border-radius: 100px;
  padding: 10px 24px;
  top: -25px;
  position: relative;
  white-space: nowrap;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0px 16px;
    padding: 14.5px 24px;
    top: 0px;
  }
}

.cta-openclimat-container {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.tooltip .tooltip-inner {
  background-color: map.get($colors, "dark-L") !important;
  padding: 3px 8px !important;
  text-align: start !important;
  border-radius: 2px !important;
  max-width: 234px !important;
}
.tooltip .tooltip-arrow {
  display: none !important;
}
