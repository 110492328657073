@use "sass:map";
@import '../../App.scss';

@media (max-width: 992px) {
  .discover-our-solution-text {
    text-align: center;
    padding-right: 1rem;
    padding-left: 1rem !important;
    padding-top: 3rem;
    align-items: center;
    padding-left: unset;
    padding-bottom: 3rem;
  }

  #content-question {
    width: 100%;
  }

  .information-list {
    padding: 0px !important;
  }

  .details-svg {
    margin-bottom: 26px;
    padding-right: 10px;
  }
}

.table-row:hover, .table-row:hover .table-status-edit {
  background-color: map.get($colors, "grey-LLLL");
  cursor: pointer;
}

.dashboard-table {
  height: 50px;
  border-collapse: collapse;
  overflow: auto;
  position: relative;
}

.table-header {
  background-color: #F8FAFD;
  height: 56px;
  position: sticky;
  top: 0px;
  z-index: 1;
  box-shadow: 0px 2px 2px 0px rgba(176, 176, 176, 0.15);
}

.table-row {
  height: 60px;
}

.table-row-no-hover {
  height: 80px;
  border-bottom: 1px solid #F4F4F4;
}

td {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.climate-action-text {
  font-weight: 500;
}

.table-name-text {
  font-weight: 500;
}

.data-img-row {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  position: relative;
  border-radius: 100%;
  border: 1px solid rgb(238, 238, 238);
}

.link-icon-div {
  width: 18px;
  height: 18px;
  border-radius: 67.5px;
}

.is-managed-data-icon {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
}

.elipse {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  border-radius: 70px;
}

.score-one {
  background-color: map.get($colors,"score-one");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.score-two {
  background-color: map.get($colors,"score-two");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.score-three {
  background-color: map.get($colors,"score-three");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.score-four {
  background-color: map.get($colors,"score-four");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.score-five {
  background-color: map.get($colors,"score-five");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.score-six {
  background-color: map.get($colors,"score-six");
  border: 2.5px solid map.get($colors,"border-opacity");
}

.elipse-scope-one {
  min-width: 8px;
  min-height: 8px;
  border-radius: 70px;
}

.score-scope-one {
  background-color: map.get($colors,"score-one");
}

.score-scope-two {
  background-color: map.get($colors,"score-two");
}

.score-scope-three {
  background-color: map.get($colors,"score-three");
}

.score-scope-four {
  background-color: map.get($colors,"score-four");
}

.score-scope-five {
  background-color: map.get($colors,"score-five");
}

.score-scope-six {
  background-color: map.get($colors,"score-six");
}

.status-div {
  border-radius: 100px;
  padding: 8px 16px;
  text-align: center;
}

.status-div-competency {
  border-radius: 100px;
  padding: 4px 8px;
  text-align: center;
}

.profile-card-logo {
  border-radius: 6.5px;
  border: 2px solid #FAFAFB;
  max-width: 40px;
  max-height: 40px;
}

.mobile-card-header {
  border-bottom: 1px solid #D9E6FA;
  padding: 12px;
}

.co2-arrow-course-card {
  display: flex;
  align-self: center!important;
  margin-right: 12px;
  @media (max-width: 576px) {
    margin-right: 8px;
  }
}

.action-svg {
  background-color: map.get($colors, "new-branding-primary");
  width: 100%;
  height: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.table-status-edit {
  background-color: white;
}

.select-status {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../assets/ui/caret-down.svg') !important;
  background-repeat: no-repeat !important;
}

tbody, thead {
  display: block !important;
}

thead th {
  height: 57px !important;
}
