@use 'sass:map';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'MoskauGrotesk';
  src: url('../fonts/moskau-grotesk-light.woff2') format('woff2'), url('../fonts/moskau-grotesk-light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MoskauGrotesk';
  src: url('../fonts/moskau-grotesk-normal.woff2') format('woff2'), url('../fonts/moskau-grotesk-normal.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MoskauGrotesk';
  src: url('../fonts/moskau-grotesk-bold.woff2') format('woff2'), url('../fonts/moskau-grotesk-bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
}

.poppins {
  font-family: 'Poppins' !important;
}

h1, h2, h3, h4, h5, h6, h1 *, h2 *, h3 *, h4 *, h5 *, h6 * {
  font-family: 'MoskauGrotesk', sans-serif !important;
  font-weight: 600;
}

.anim-all {
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.anim-all-1s {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.border-5 {
  border-radius: 5px !important;
}
.border-8 {
  border-radius: 8px !important;
}
.border-10 {
  border-radius: 10px !important;
}
.border-14 {
  border-radius: 14px !important;
}
.border-18 {
  border-radius: 18px !important;
}
.border-20 {
  border-radius: 20px !important;
}
.border-25 {
  border-radius: 25px !important;
}
.border-50 {
  border-radius: 50px !important;
}

.shadow {
  box-shadow: 0px 34px 34px rgba(80, 95, 238, 0.04) !important;
}
$colors: (
  'white': #ffffff,
  'black': #000000,
  'red': #b02418,
  'red-H': #e93c3c,
  'red-L': #ff6262,
  'orange': #ff7140,
  'corail': #eca3ac,
  'orange-L': #fbe3d4,
  //rgba(255, 113, 64, 0.2);
  'green': #39b54a,
  'yellow': #fff125,
  'light-yellow': #fdefd5,
  'light-grey': #f1f2fe,
  'grey-four': #9398AC,

  'new-branding-primary': #031356,
  'primary': #505fee,
  'primary-HHH': #171725,
  'primary-HH': #150234,
  'primary-H': #0b13a8,
  'primary-L': #4049f3,
  'primary-LL': #666df5,
  'primary-LLL': #f4f5fe,
  'primary-text': #ffffff,
  'violet': #7e8bff,

  'secondary': #a4b0f5,
  'secondary-HH': #4f5677,
  'secondary-H': #727bab,
  'secondary-L': #b6bff7,
  'secondary-LL': #c4cbf8,
  'secondary-text': #ffffff,

  'third': #c5decd,
  'third-HH': #5f6c64,
  'third-H': #899b8f,
  'third-L': #d0e4d7,
  'third-LL': #d9e9df,
  'third-text': #000000,

  'fourth': #eec6ca,
  'fourth-HH': #746062,
  'fourth-H': #a68a8d,
  'fourth-L': #f1d1d4,
  'fourth-LL': #f3dadc,
  'fourth-text': #000000,

  'grey': #abacaf,
  'grey-HHH': #30263f,
  'grey-HH': #77787a,
  'grey-H': #92929d,
  'grey-L': #696974,
  'grey-LL': #f8f9fb,
  'grey-lll': #f2f2f2,
  'grey-LLL': #f3f3f3,
  'grey-LLLL': #f4f4f4,
  'grey-LLLLL': #f8fafd,
  'grey-text': #000000,
  'cloudy-grey': #746f7c,
  'cloudy-white': #f4f4f4,
  'cloudy-white-L': #fafafb,
  'grey-muted': #959595,
  'grey-muted-L': #d9e6fa,

  'dark': #171725,
  'dark-L': #44444f,
  'sme-blue': #0150ac,
  'white-LL': #f6f6f6,

  'carbon-footprint-scope-3-1': #1f255c,
  'carbon-footprint-scope-12-1': #eca3ac,

  'score-one': #77150c,
  'score-two': #b02518,
  'score-three': #ef8f4c,
  'score-four': #f6ca45,
  'score-five': #9fdd5c,
  'score-six': #54b534,
  'border-opacity': #ffffff70,

  'graph-score-one': #d89d9b,
  'graph-score-two': #e7c3c2,
  'graph-score-three': #efd1ac,
  'graph-score-four': #faefc7,
  'graph-score-five': #d7e6cd,
  'graph-score-six': #c5dab4,
);

@for $i from 100 through 900 {
  .fw-#{$i} {
    font-weight: #{$i};
  }
}

@each $name, $value in $colors {
  .#{$name}-bg {
    background-color: #{$value};
  }

  .#{$name}-color {
    color: #{$value} !important;
  }

  .btn-#{$name} {
    background-color: #{$value};
    border-color: transparent !important;
    &:hover {
      background-color: #{$value};
      opacity: 0.9;
    }
  }
}

@for $i from 1 through 50 {
  .lh-#{$i} {
    line-height: #{$i}px;
  }
}

@for $i from 7 through 70 {
  .font-#{$i} {
    font-size: #{$i}px;
  }
}
@media (min-width: 768px) {
  @for $i from 7 through 70 {
    .font-md-#{$i} {
      font-size: #{$i}px;
    }
  }
}

.ps-4-5 {
  padding-left: 2.4rem !important;
}

.p-4-5 {
  padding: 1.9rem;
}

.p-16 {
  padding: 16px;
}

.primary-LLL-hover:hover {
  background-color: map.get($colors, 'primary-LLL') !important;
  border-radius: 4px;
}

body,
body.light-mode {
  background: map.get($colors, 'grey-LLLLL');
  color: map.get($colors, 'primary-HH') !important;
}

.profile-logo {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 30px;
  border: 8px solid #f7f7f7;
  width: 180px;
  height: 180px;
  position: relative;
  top: -55px;
  margin-bottom: -55px;
  img {
    border-radius: 30px;
  }
}

.custom-list ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.custom-list ol {
  list-style-type: decimal;
  padding-left: 2rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.custom-list p {
  margin-bottom: 1em;
}