@use "sass:map";
@import "../../App.scss";

.title-profil-page {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #171725;
  letter-spacing: 0.12px;
}

.item-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #92929d;
}

.item-label-update {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: map.get($colors, "primary");
}

#profile-page {
  background-color: white;

  header {
    min-height: 200px;
    color: map.get($colors, "grey");

    padding-top: 140px;

    a:not(.btn) {
      color: map.get($colors, "primary");
      text-decoration: none;
    }
  }

  #sub-header {
    text-align: center;
    a {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.2px;
    }
    .subview {
      position: relative;

      .custom-border-bottom {
        height: 2px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      &:not(.view-active):hover {
        
        .custom-border-bottom {
          background: map.get($colors, "primary-LL");
        }
      }
      &.view-active {

        .custom-border-bottom {
          background: map.get($colors, "primary");
        }
      }
    }
  }

  .answer {
    font-weight: 400;
    line-height: 21px;
    color: #696974;
  }

  .content-list-item {
    .content-list-item-action {
      opacity: 0;
      @extend .anim-all;
    }
    &.editable:hover {
      background: map.get($colors, "grey-LL");
      .content-list-item-action {
        opacity: 1;
      }
    }
  }
}

.content-list-item {
  padding: 8px 12px 8px 12px;
}

.profile-question {
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #171725;
  }
  p.info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #150234;
    margin: 0;
  }
  .answer {
    font-size: 14px;
    white-space: pre-line;
  }
}
.profile-action {
  .answer {
    font-size: 14px;
  }
  .content-type-3 {
    background: #ffffff;
    border-radius: 12px;
    font-weight: 400;
    margin: 0;
    width: 100%;
    p {
      margin: 0;
    }
  }
}

.warning-color {
  background-color: map.get($colors, "light-yellow");
}

#legendContainer ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  li {
    list-style: none;
    font-size: 12px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .dot {
    min-height: 16px;
    min-width: 16px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}

.achievement-container {
  background: linear-gradient(258.88deg, #2133c8 41.78%, #3a4dde 88.11%);
}

.achievement-badge {
  background-color: rgba(255, 255, 255, 0.12);
  img {
    height: 16px;
  }
}

.list-item-div {
  display: list-item;
}

.library-element {
  height: 100px;
  width: 47%;
  &:hover {
    .actions {
      opacity: 1;
    }
  }
  .actions {
    opacity: 0;
    @extend .anim-all;
  }
}

.library-image-preview {
  object-fit: cover;
}

@media (max-width: 768px) {
  .library-element {
    width: 100%;
  }
}

.competing-company-item {
  .competing-premium-link {
    &.competing-redirect {
      border: 1px solid #746f7c !important;
      &.managed {
        border: 1px solid map.get($colors, "primary") !important;
        color: map.get($colors, "primary") !important;
      }
      border-radius: 50px;
      width: fit-content;
    }
    color: black;
    text-decoration: none;
    &.competing-redirect:hover {
      color: map.get($colors, "primary");
      border: 1px solid map.get($colors, "primary") !important;
    }
  }
  min-height: 50px;
}

.data-shared-card {
  background-color: #f8fafd;
}
.data-not-shared-card {
  background-color: #f4f4f4;
  color: #746f7c;
}

.committed-sme {
  background: rgba(1, 80, 172, 0.06);
  border-radius: 10px;
}

.progress-bar {
  background: #f4f4f4;
  border-radius: 6px;
  height: 14px;
  overflow: revert;
  z-index: 2;
  width: 100%;
  top: 30%;
}
.progress-bar-graduation {
  div {
    z-index: 1;
    margin-top: 10px;
  }
}
.progression-bar {
  z-index: 3;
  height: 14px;
  top: 30%;
}

.ce-paragraph.cdx-block {
  white-space: pre-wrap;
}

.answer > div > p {
  white-space: pre-wrap;
  margin-bottom: 0;
}

.data-carbon-sources {
  word-break: normal;
}

.left-side-actions {
  position: sticky;
  top: 6rem;
}

.chart-container {
  border-radius: 10px;
  overflow: visible;
}

.codex-editor__redactor {
  padding-bottom: 10px !important;
}

.fit-content {
  width: fit-content;
}

.eye-slash {
  width: 26px;
}

.publishing-switch {
  height: 21px !important;
  width: 40px !important;
  cursor: pointer;
}

.sources-fiability-svg {
  width: 21px;
  height: 21px;
}

.padding-s-13 {
  padding-left: 13px;
}

.mt-4-5 {
  margin-top: 32px;
}

.sources-svg {
  padding-left: 2px;
}

@media (min-width: 1268px) {
  .tooltip-inner {
    max-width: 350px;
  }
}
.more-info-label-svg {
  height: 50px;
}

@media (min-width: 768px) {
  .update-date-container {
    display: none;
  }
}

.link-hover-grey:hover {
  background-color: #92929d;
}

.required-field-form:after {
  content: "* ";
  color: black;
}

.profile-modal-button {
  padding: 4px;
  height: 30px;
  width: 30px;
}

.profile-private-questions-modal-button {
  padding: 2px !important;
}

.pt-05 {
  padding-top: 2px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ffffff;
  border-bottom: none;
  border: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #fff !important;
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fff !important;
}

.accordion-button:hover {
  background-color: map.get($colors, "grey-LL");
}

.year-percentage-block {
  border-radius: 20px;
}

.carbon-percentage-widget {
  min-width: 129px;
}

.current-climate-actions-legend-color {
  width: 20px;
  height: 25px;
}

// .border-top {
//   border-radius: 4px 4px 0px 0px;
// }

// .border-bottom {
//   border-radius: 0px 0px 4px 4px;
// }

.current-climate-actions-legend-color-small {
  min-width: 15px;
  min-height: 15px;
  border-radius: 4px;
  margin-bottom: 3px;
  border: white solid 2px;
}

.current-climate-actions-legend-border {
  width: 8px;
  height: 25px;
  border-bottom: map.get($colors, "cloudy-grey") solid 1px;
}

.current-climate-actions-legend-text-container {
  position: relative;
  top: 16px;
  left: 7px;
}

.current-climate-actions-legend-text {
  height: 25px;
}

.current-climate-actions-legend-text-first {
  height: 25px;
}

.current-climate-actions-circle {
  min-width: 18px;
  height: 18px;
  border-radius: 70px;
}

.current-actions-legend-block-two {
  margin-top: 16px !important;
}

.climatic-compatibility-block {
  padding-left: 14px;
  padding-right: 14px;
}

.climate-compatibily-container {
  width: 58%;
}

.info-full-svg {
  margin-bottom: 2px;
  margin-right: 4px;
}

.campaign-cartridge-container-large {
  position: relative;
  top: -40px;
  border: 5px solid map.get($colors, "grey-LLLL");
  width: 83%;
}

.campaign-cartridge-container-medium {
  position: relative;
  top: -40px;
  border: 5px solid map.get($colors, "grey-LLLL");
  width: 65%;
}

.campaign-note-container {
  border-left: 1px map.get($colors, "cloudy-grey") dotted;
}

.partner-logo-container {
  display: flex;
  align-self: center;
  padding-right: 2px;
  padding-left: 4px;
}

@media (max-width: 1200px) {
  .climate-compatibily-container {
    width: 82%;
  }
}

@media (max-width: 1400px) {
  .campaign-cartridge-container-large,
  .campaign-cartridge-container-medium {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .campaign-cartridge-container-large,
  .campaign-cartridge-container-medium {
    width: 100%;
    top: 0px;
  }
}

@media (max-width: 768px) {
  .campaign-cartridge-container-large,
  .campaign-cartridge-container-medium {
    width: 100%;
    top: 0px;
  }

  .campaign-cartridge-container-large,
  .campaign-cartridge-container-medium {
    flex-direction: column;
  }

  .partner-logo-container {
    display: block;
    text-align: center;
    width: 100%;
    border-bottom: 3px solid map.get($colors, "grey-LL");
  }
}

@media (max-width: 768px) {
  .accordion-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .accordion-button {
    padding-left: 0px;
    padding-right: 0px;
  }

  .carbon-percentage-widget {
    padding: 4px !important;
  }

  .climatic-compatibility-block {
    max-width: 129px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .current-climate-actions-legend-text {
    font-size: 12px;
  }

  .current-climate-actions-legend-text-first {
    position: relative;
    top: -10px;
  }
}

.cta-header-profile {
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  gap: 10px;
  background: linear-gradient(
    90deg,
    map.get($colors, "primary") 0%,
    #ed7878 100%
  );
}

#emissionsScope12::-webkit-inner-spin-button {
  display: none;
}

#emissionsScope3::-webkit-inner-spin-button {
  display: none;
}

#carbonMixBalanceYear::-webkit-inner-spin-button {
  display: none;
}

.summary {
  padding: 24px 28px 24px 28px;
  top: 85px!important;
}

.summary-disclaimer {
  padding: 24px 28px 24px 28px;
  top: 85px;
  border: map.get($colors, "primary") solid 1px;
}

.summary-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.summary-first-question {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.answer-block {
  margin-top: -85px;
  padding-top: 85px;
}

.summary-question {
  color: black;
}

.summary-question-empty {
  color: map.get($colors, "grey-four");
}

.summary-question:hover {
  color: black;
  background-color: map.get($colors, "primary-LLL");
  border-radius: 4px;
  cursor: pointer;
}

.summary-question-empty:hover {
  color: rgba(148, 160, 173, 1);
  background-color: map.get($colors, "primary-LLL");
  border-radius: 4px;
  cursor: pointer;
}

.summary-progression-bar-container {
  height: 8px;
  width: 65%;
  border-radius: 4px;
}

.summary-progression-bar {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: map.get($colors, "primary-LLL");
}

.summary-progression-bar-fill {
  background: rgba(80, 95, 238, 1);
  height: 8px;
  border-radius: 4px;
}

.question-container {
  margin-top: -85px;
  padding-top: 85px;
}

@media (max-width: 768px) {
  .summary {
    display: none;
  }
}

.distribution-percent-container {
  background-color: map.get($colors, "primary-LLL");
  padding: 2px 4px 2px 4px;
  width: 40px;
  max-height: 24px;
}

.summary-subtext-container {
  background-color: map.get($colors, "primary-LLL");
  padding: 2px 8px 2px 8px;
}

/* Custom CSS for hiding the horizontal scrollbar */
.custom-horizontal-scrollbar {
  overflow-x: auto;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.custom-horizontal-scrollbar::-webkit-scrollbar {
  display: none;
}

.shadowHider {
  position: absolute;
  height: 56px;
  width: 1rem;
  background-color: white;
  z-index: 2;
}
.shadowHider--end {
  position: sticky;
  margin-left: -2rem;
  flex: 0 0 1rem;
  left: calc(100% - 1rem);
}

.shadow-start {
  content: "";
  position: sticky;
  z-index: 1;
  height: 56px;
  flex: 0 0 28px;
}

.shadow-end {
  content: "";
  position: sticky;
  z-index: 1;
  height: 56px;
  flex: 0 0 28px;
}

.shadow--start {
  left: 0;
  background: linear-gradient(
    270deg,
    rgba(248, 250, 253, 0) 0%,
    rgba(248, 250, 253, 0.8197872899159664) 33%,
    rgba(248, 250, 253, 1) 76%
  );
  pointer-events: none;
}

.shadow--end {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(248, 250, 253, 0) 0%,
    rgba(248, 250, 253, 0.8197872899159664) 33%,
    rgba(248, 250, 253, 1) 76%
  );
  pointer-events: none;
}

.private-questions-publishing-switch {
  height: 17px !important;
  width: 34px !important;
  cursor: pointer;
}

.raw-answer-container p:last-child {
  margin-bottom: 0px;
}

.carbon-footprint-circle {
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 100px;
}

.tooltip-container-scope3 {
  padding: 6px 12px 6px 12px;
  border-radius: 100px;
  color: #000d85ad;
  background-color: #000d850d;
  white-space: nowrap;
}

.tooltip-container-scope12 {
  padding: 6px 12px 6px 12px;
  border-radius: 100px;
  color: #0ecae3;
  background-color: #00e0ff0d;
  white-space: nowrap;
}

.vertical-separator-scope3 {
  height: 100%;
  width: 0px;
  border: 1px solid #E1E3EB;
}

.vertical-separator-scope12 {
  height: 100%;
  width: 0px;
  border: 1px solid #E1E3EB;
}

.no-carbon-footprint {
  height: 144px;
  border-radius: 8px;
}

.carbon-footprint-tooltip > .tooltip-inner {
  text-align: left !important;
  max-width: 420px;
}

.carbon-footprint-chart-container {
  height: 255px;
  overflow: visible;
}

.carbon-footprint-chart-container > div {
  overflow: visible;
}

.carbon-footprint-chart-container > div > div {
  overflow: visible;
}

.carbon-footprint-chart-container > div > div > svg {
  overflow: visible;
}

.to-parent-profile-button {
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  background-color: map.get($colors, "primary");
  color: white;
  cursor: pointer;
}

.to-parent-profile-button:hover {
  color: white;
}

.logo-filiale {
  border: 1px solid #f7f7f7;
  border-radius: 4px;
}

.border-top-18 {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.cta-actions-plan {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #eeeffe;
  color: map.get($colors, "primary");
  font-weight: 500;
  font-size: 14px;
  padding: 12px 16px 12px 24px;
}

.cta-actions-plan:hover {
  color: map.get($colors, "primary");
  background-color: #dfe1ff;
}

.item-details {
  display: inline-block;
  overflow-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: map.get($colors, "dark-L");
}
