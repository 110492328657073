.table-wrapper {
    width: 100%;
    overflow-x: auto;
  }
  
  .full-width-table {
    width: 100%;
    min-width: 800px;
    table-layout: fixed;
  }
  
  .full-width-table tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  
  .full-width-table th,
  .full-width-table td {
    padding: 12px;
    text-align: center;
  }
  
  .full-width-table thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: rgb(249, 250, 251);
  }
  
  .full-width-table tbody {
    display: block;
    max-height: calc(100vh - 450px);
    overflow-y: scroll;
    scrollbar-width: none;  /* Pour Firefox */
    -ms-overflow-style: none;  /* Pour Internet Explorer et Edge */
  }
  
  /* Pour Chrome, Safari et Opera */
  .full-width-table tbody::-webkit-scrollbar {
    display: none;
  }
  
  /* Style spécifique pour la cellule avec image */
  .full-width-table td:first-child {
    padding: 0;
  }
  
  .supplier-cell-content {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    width: 100%;
    min-width: 0;
  }
  
  .supplier-image {
    width: 40px;
    height: 40px;
    min-width: 40px;
    object-fit: contain;
  }
  
  .supplier-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
  
  /* Alignement à gauche pour la première colonne */
  .full-width-table th:first-child,
  .full-width-table td:first-child {
    text-align: left;
  }
  
  /* Largeurs spécifiques pour chaque colonne */
  .full-width-table th:nth-child(1),
  .full-width-table td:nth-child(1) {
    width: 22%;
  }
  
  .full-width-table th:nth-child(2),
  .full-width-table td:nth-child(2) {
    width: 13%;
  }
  
  .full-width-table th:nth-child(3),
  .full-width-table td:nth-child(3) {
    width: 30%;
  }
  
  .full-width-table th:nth-child(4),
  .full-width-table td:nth-child(4) {
    width: 22%;
  }
  
  .full-width-table th:nth-child(5),
  .full-width-table td:nth-child(5) {
    width: 13%;
  }